import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import Nav from './Navigate'
import Tail from './Tail'
import Loaderpage from './Loaderpage';
import lao_address from './lao_address'
import Captcha from "react-numeric-captcha";
class Register extends Component {

    componentDidMount() {
        document.title = "Miyazaki | Register"
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            isLoading: 0
        }
        ))
    }
    constructor(props) {
        super(props);
        this.state = {
            info: {
                card_number: "LAO-",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                password: "",
                alternate_phone: "",
                address: "",
                gender: "",
                age: ""
            },
            status: "",
            email_error: "",
            pass_warn: "",
            form_warn: {
                "Member.CardNumber": "*",
                "Member.Email": "",
                "Member.FirstName": "*",
                "Member.LastName": "*",
                "Member.Password": "*",
                "Member.Phone": "*",
                "Member.AlterPhone": ""
            }, message: "",
            cfpassword: "",
            new_pass_warn: "",
            phone_error: "",
            alternate_phone_error: "",
            phone: "",
            alternate_phone: "",
            isLoading: 1,
            province: "",
            district: "",
            district_list: <></>,
            captchaSuccess: false,
            statuscaptcha: ""
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
        this.validatePass = this.validatePass.bind(this);
        this.validateNewPass = this.validateNewPass.bind(this);
        this.onInputaddress = this.onInputaddress.bind(this);
        this.listchoose = this.listchoose.bind(this);
        this.choose_province = this.choose_province.bind(this);
        this.choose_district = this.choose_district.bind(this);
    }

    onInputchange(event) {
        var regex = /"|=|<|>/;
        if (!regex.test(event.target.value)) {
            this.setState(Object.assign(
                this.state, {
                info: {
                    ...this.state.info,
                    [event.target.name]: event.target.value,
                },
                form_warn: {
                    ...this.state.form_warn,
                    "Member.FirstName": "",
                    "Member.LastName": ""
                }
            }
            ));
        }
    }
    choose_district(event) {
        var address_cat;
        address_cat = this.state.province.concat("," + event.target.value)
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            district: event.target.value,
            info: {
                ...this.state.info,
                address: address_cat
            }
        }
        ))
    }
    choose_province(event) {
        const { t } = this.props;
        var data = [];
        var district;
        for (district in lao_address[event.target.value]) {
            data.push(<option key={district} value={lao_address[event.target.value][district]}>{t(lao_address[event.target.value][district])}</option>)
        }
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            province: event.target.value,
            district_list: data
        }
        ))
    }
    onInputaddress(event) {
        var regex = /"|=|<|>/;
        if (!regex.test(event.target.value)) {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                [event.target.name]: event.target.value,
            }
            ));
        }
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            info: {
                ...this.state.info,
                address: this.state.province.concat("," + this.state.district)
            }
        }
        ))
    }

    validatePass(event) {
        const passregex = /^.{8,}$/
        var star
        var password = event.target.value;
        var pass_warn_text;
        if (passregex.test(password)) {
            pass_warn_text = ""
            star = "*"
        } else {
            pass_warn_text = "pass_warn"
            star = ""
        }
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            pass_warn: pass_warn_text,
            info: {
                ...this.state.info,
                [event.target.name]: event.target.value
            }, form_warn: {
                ...this.state.form_warn,
                "Member.Password": star
            },
        }
        )
        );
    }
    validatePhoneNumber(event) {
        var number = event.target.value.replace(" ", "");
        const numregex = /020+[0-9]{8}|030+[0-9]{7}/;
        var message = event.target.name + "_error"
        if (numregex.test(number)) {
            var res = number.slice(3);
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                info: {
                    ...this.state.info,
                    [event.target.name]: res
                },
                [event.target.name + "_error"]: ""
            }))
        } else {
            if (number === "") {
                this.setState(Object.assign(
                    this.state, {
                    ...this.state,
                    info: {
                        ...this.state.info,
                        [event.target.name]: "",
                        [event.target.name + "_error"]: ""
                    }
                }))
            }
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                [event.target.name + "_error"]: message,
            }))
        }
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            form_warn: {
                ...this.state.form_warn,
                "Member.Phone": ""
            },
            [event.target.name]: event.target.value
        }))
    }
    validateNewPass(event) {
        if (this.state.info.password !== event.target.value) {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                new_pass_warn: "cfpasswrong",
                cfpassword: event.target.value
            }
            ));
        } else {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                new_pass_warn: "",
                cfpassword: event.target.value
            }
            ));
        }
    }

    listchoose(event) {
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            info: {
                ...this.state.info,
                [event.target.name]: event.target.value
            }
        }
        ))
    }

    onSubmitForm() {
        if (this.state.new_pass_warn !== "cfpasswrong" && this.state.captchaSuccess && this.state.info.password && this.state.info.card_number !== "LAO-") {
            axios.post(process.env.REACT_APP_API.concat('members'), this.state.info)
                .then(res => {
                    this.setState(
                        Object.assign(
                            this.state, {
                            ...this.state,
                            status: "success",
                        })
                    )
                    setTimeout(() => {
                        window.location.href = "/"
                    }, 3000);
                }).catch(err => {
                    if (err.response) {
                        if (err.response.data instanceof Array) {
                            err.response.data.forEach(
                                (message) => {
                                    this.setState(
                                        Object.assign(
                                            this.state, {
                                            ...this.state,
                                            status: err.response.data.message,
                                            form_warn: {
                                                ...this.state.form_warn,
                                                [message.FailedField]: message.Tag.concat([message.FailedField])
                                            }
                                        })
                                    )
                                }
                            )
                        } else {
                            if (err.response.data.FailedField) {
                                this.setState(
                                    Object.assign(
                                        this.state, {
                                        ...this.state,
                                        form_warn: {
                                            [err.response.data.FailedField]: err.response.data.Tag.concat([err.response.data.FailedField])
                                        }
                                    })
                                )
                            } else {
                                this.setState(
                                    Object.assign(
                                        this.state, {
                                        ...this.state,
                                        status: err.response.data.message,
                                    })
                                )
                            }

                        }
                    } else {
                        this.setState({
                            ...this.state,
                            status: "net_problem"
                        })
                    }
                })
        } else {
            if (!this.state.captchaSuccess) {
                this.setState(
                    Object.assign(
                        this.state, {
                        ...this.state,
                        statuscaptcha: "captcha wrong",
                        status: "checkplease",
                    })
                )
            } else {
                this.setState(
                    Object.assign(
                        this.state, {
                        ...this.state,
                        status: "checkplease",
                        statuscaptcha: "",
                    })
                )
            }
        }

    }

    render() {
        const { t } = this.props;
        if (this.state.isLoading === 0) {
            return (
                <>
                    <Nav />
                    <div className="tab-container">
                        <div className="tabs divcenter 
                     clearfix ui-tabs ui-widget ui-widget-content ui-corner-all" id="tab-login-register">
                            <div className="tab-content clearfix" id="tab-register">
                                <div className="card card-default">
                                    <div className="card-body">
                                        <ul className="fa-ul ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all" >
                                            <li>
                                                <h3>
                                                    <i className="far fa-check-circle"></i> {t('regtitle')}
                                                </h3>
                                            </li>
                                        </ul>
                                        <form className="nobottommargin" onSubmit={this.onSubmitForm}>
                                            <div className="col_full">
                                                <div className="mb-3">
                                                    <label htmlFor="register-form-cardno">
                                                        {t('cardnum')} :<label className="text-danger">{t(this.state.form_warn["Member.CardNumber"])}</label>
                                                        <code className="text-danger"> {t(this.state.form_warn.Unique)} </code>

                                                    </label>
                                                    {/* <p>
                                                <span className="badge bg-info">Note:</span>
                                                <code> {t('bcard')}</code>
                                                </p> */}
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="far fa-credit-card"></i></span>
                                                        <input type="text" name="card_number" className="form-control uppercase" maxLength="16" placeholder={t('cardnum')} value={this.state.info.card_number} onChange={this.onInputchange} required></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col_full">
                                                <div className="mb-3">
                                                    <label htmlFor="register-form-cardno">
                                                        {t('fname')} :
                                                        <span className="text-danger">{t(this.state.form_warn["Member.FirstName"])}</span>
                                                    </label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="far fa-id-card"></i></span>
                                                        <input type="text" name="first_name" className="form-control" placeholder={t('fname')} value={this.state.info.first_name} onChange={this.onInputchange} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col_full">
                                                <div className="mb-3">
                                                    <label htmlFor="register-form-cardno">
                                                        {t('lname')} :
                                                        <span className="text-danger">{t(this.state.form_warn["Member.LastName"])}</span>
                                                    </label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="far fa-id-card"></i></span>
                                                        <input type="text" name="last_name" className="form-control" placeholder={t('lname')} value={this.state.info.last_name} onChange={this.onInputchange} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <label >{t('Gender')}</label> :<label className="text-danger">*</label>
                                                    <select className="form-control" value={this.state.info.gender} name="gender" onChange={this.listchoose}>
                                                        <option defaultValue={"..."}>...</option>
                                                        <option value={"MALE"}>{t('male')}</option>
                                                        <option value={"FEMALE"}>{t('female')}</option>
                                                        <option value={"OTHER"}>{t('not-specified')}</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <label >{t('ages')}</label> :<label className="text-danger">*</label>
                                                    <select className="form-control" value={this.state.info.age} name="age" onChange={this.listchoose}>
                                                        <option defaultValue={"..."}>...</option>
                                                        <option value={"17"}>{t('below18')}</option>
                                                        <option value={"18-25"}>{t('18-25')}</option>
                                                        <option value={"26-45"}>{t('26-45')}</option>
                                                        <option value={"46"}>{t('older45')}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col nolpad">
                                                    <label htmlFor="register-form-cardno">{t('province')} : <label className="text-danger"></label> </label><label className="text-danger">*</label>
                                                    <select id="inputState" className="form-control" value={this.state.province} onChange={this.choose_province}>
                                                        <option defaultValue={"..."}>...</option>
                                                        <option value={"Vientiane capital"}>{t("Vientiane capital")}</option>
                                                        <option value={"Phongsali"}>{t("Phongsali")}</option>
                                                        <option value={"Louang Namtha"}>{t("Louang Namtha")}</option>
                                                        <option value={"Oudomxai"}>{t("Oudomxai")}</option>
                                                        <option value={"Bokeo"}>{t("Bokeo")}</option>
                                                        <option value={"Louang Phabang"}>{t("Louang Phabang")}</option>
                                                        <option value={"Houaphan"}>{t("Houaphan")}</option>
                                                        <option value={"Xaignabouli"}>{t("Xaignabouli")}</option>
                                                        <option value={"Xiangkhoang"}>{t("Xiangkhoang")}</option>
                                                        <option value={"Vientiane"}>{t("Vientiane")}</option>
                                                        <option value={"Boli khamxai"}>{t("Boli khamxai")}</option>
                                                        <option value={"Khammouan"}>{t("Khammouan")}</option>
                                                        <option value={"Savannakhet"}>{t("Savannakhet")}</option>
                                                        <option value={"Salavan"}>{t("Salavan")}</option>
                                                        <option value={"Xekong"}>{t("Xekong")}</option>
                                                        <option value={"Champasak"}>{t("Champasak")}</option>
                                                        <option value={"Attapu"}>{t("Attapu")}</option>
                                                        <option value={"Sisomboun"}>{t("Sisomboun")}</option>
                                                        <option value={"foreign"}>{t("foreign")}</option>
                                                    </select>
                                                </div>
                                                <div className="col norpad">
                                                    <label htmlFor="register-form-cardno">{t('district')} :<label className="text-danger"></label>  </label><label className="text-danger">*</label>
                                                    <select className="form-control" value={this.state.district} onChange={this.choose_district}>
                                                        <option defaultValue={"..."}>...</option>
                                                        {this.state.district_list}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col_full">
                                                <div className="mb-3">
                                                    <label htmlFor="register-form-cardno">
                                                        {t('pnumber')} :
                                                        <span className="text-danger">{t(this.state.form_warn["Member.Phone"])} {t(this.state.phone_error)} </span>
                                                    </label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="fas fa-mobile-alt"></i></span>
                                                        <input type="text" name="phone" className="form-control" maxLength="11" placeholder={t('phonedegit')} value={this.state.phone} onChange={this.validatePhoneNumber} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col_full">
                                                <div className="mb-3">
                                                    <label htmlFor="register-form-cardno">
                                                        {t('pnumber-nd')} :
                                                        <span className="text-danger">{t(this.state.form_warn["Member.AlterPhone"])} {t(this.state.alternate_phone_error)} </span>
                                                    </label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="fas fa-mobile-alt"></i></span>
                                                        <input type="text" name="alternate_phone" className="form-control" maxLength="11" placeholder={t('phonedegit')} value={this.state.alternate_phone} onChange={this.validatePhoneNumber} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col_full">
                                                <div className="mb-3">

                                                    <label htmlFor="register-form-user">
                                                        {t('username')}:
                                                        <span className="text-danger"> {t(this.state.email_error)} {t(this.state.form_warn["Member.Email"])}</span>
                                                    </label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="far fa-id-card"></i></span>
                                                        <input type="text" name="email" className="form-control" placeholder={t('mail')} value={this.state.info.email} onChange={this.onInputchange} required></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label>
                                                        {t('password')} : <label className="text-danger">{t(this.state.pass_warn)} {t(this.state.form_warn["Member.Password"])}</label>

                                                    </label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="fas fa-key"></i></span>
                                                        <input type="password" name="password" className="form-control" placeholder="**********" value={this.state.info.password} onChange={this.validatePass} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label>
                                                        {t('cfpass')} : <label className="text-danger">*{t(this.state.new_pass_warn)}</label>

                                                    </label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="fas fa-key"></i></span>
                                                        <input type="password" name="password" className="form-control" placeholder="**********" value={this.state.cfpassword} onChange={this.validateNewPass} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label>
                                                        {t("reg-text1")} <label className="text-danger">*</label>{t("reg-text2")}
                                                    </label>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="col mb-3 ">
                                            <Captcha onChange={status => this.setState({ captchaSuccess: status })} />
                                            &nbsp;<span className="text-danger">{t(this.state.statuscaptcha)}</span>
                                        </div>
                                        <div className="col mb-3 nobottommargin">
                                            <button className="button button-3d button-primary nomargin" onClick={this.onSubmitForm}>{t('register')}</button>
                                            &nbsp;&nbsp;<span className="text-danger">{t(this.state.status)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tail />
                </>
            )
        } else {
            return (<Loaderpage />)
        }

    }
}
export default withTranslation()(Register);