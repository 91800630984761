import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import Crypto from 'crypto-js'
import Loaderpage from './Loaderpage'
import Nav from './Navigate'
import Tail from './Tail'
import Captcha from "react-numeric-captcha";
class Login extends Component {
    componentDidMount() {
        document.title = "Miyazaki | Login"
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            isLoading: 0
        }
        ))
    }
    constructor(props) {
        super(props);
        this.state = {
            email_error: "",
            login: {
                identifier: "",
                password: ""
            },
            identifier: "LAO-MY",
            status: "",
            isLoading: 1,
            header: "LAO-MY",
            placeholder: "LAO-MY.......",
            captchaSuccess: false,
            statusCaptcha: ""
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        // this.validateEmail = this.validateEmail.bind(this);
        this.validateNumber = this.validateNumber.bind(this);
        this.listchoose = this.listchoose.bind(this);

    }

    onInputchange(event) {
        this.setState(Object.assign(
            this.state, {
            login: {
                ...this.state.login,
                [event.target.name]: event.target.value

            }
        }
        )
        );
    }

    validateNumber(event) {
        var number = event.target.value;
        if (number.length > 9 || number.length < 12) {
            const numregex = /020+[0-9]{8}|030+[0-9]{7}/;
            var res = number.slice(3);
            if (numregex.test(number)) {
                this.setState(Object.assign(
                    this.state, {
                    ...this.state,
                    login: {
                        ...this.state.login,
                        identifier: res
                    },
                    phone_error: ""
                }))
            } else {
                // this.setState(Object.assign(
                //     this.state,{
                //         ...this.state,
                //         login:{
                //             ...this.state.login,
                //             identifier:""
                //         },
                //         phone_error : message,
                //     }))
                this.setState(Object.assign(
                    this.state, {
                    login: {
                        ...this.state.login,
                        [event.target.name]: event.target.value.replace(" ", "")
                    }
                }))
            }
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                [event.target.name]: event.target.value.replace(" ", "")
            }))
        } else {

        }
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            [event.target.name]: event.target.value.replace(" ", "")
        }))
    }

    gotoreg() {
        window.location.href = "/register";
    }


    onSubmitForm() {
        if (this.state.captchaSuccess === true) {
            axios.post(process.env.REACT_APP_API.concat('auth/member-login'), this.state.login)
                .then(res => {

                    localStorage.setItem("access_token", Crypto.AES.encrypt(res.data.access_token, 'MKSUKI').toString())
                    localStorage.setItem("refresh_token", Crypto.AES.encrypt(res.data.refresh_token, 'MKSUKI').toString())
                    window.location.href = "/user";
                }).catch(err => {
                    if (err.response) {
                        this.setState({
                            ...this.state,
                            status: "err_login"
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            status: "net_problem"
                        })
                    }

                })
        } else {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                statusCaptcha: "captcha wrong"
            }))
        }
    }
    listchoose(event) {
        var placeholder
        if (event.target.value === "LAO-MK") {
            placeholder = "LAO-MK......."
        } else {
            placeholder = "phonedegit"
        }
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            header: event.target.value,
            identifier: event.target.value,
            placeholder: placeholder,
            login: {
                ...this.state.login,
                identifier: event.target.value
            }
        }))
    }
    render() {
        const { t } = this.props;
        if (this.state.isLoading === 0) {
            return (
                <>
                    <Nav />
                    <div className="tab-container tab-container-login">
                        <div className="tabs divcenter nobottommargin clearfix ui-tabs ui-widget ui-widget-content ui-corner-all" id="tab-login-register">
                            <div className="tab-content clearfix" id="tab-login">
                                <div className="card card-login card-default">
                                    <div className="card-body">
                                        <center className="fa-ul ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all" >
                                            <h3>
                                                <i className="fas fa-lock-open"></i> Miyazaki Card Login
                                            </h3>
                                        </center>
                                        <form id="Login-form" className="nobottommargin">

                                            <div className="col_full nobottommargin">
                                                <div className="mb-3 nobottommargin">
                                                    <label>
                                                        {t('email or phone')}
                                                        {/* <span> * </span> */}
                                                    </label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="far fa-envelope"></i></span>
                                                        <select className="form-select" style={{ width: "10rem !important" }} onChange={this.listchoose}>
                                                            <option value="LAO-MK">{t('cardno')}</option>
                                                            <option value="">{t('pnumber')}</option>
                                                        </select>
                                                        <input type="text" name="identifier" maxLength="16" className="form-control uppercase" placeholder={t(this.state.placeholder)} value={this.state.identifier} onChange={this.validateNumber} required />
                                                    </div>
                                                </div>
                                                <label style={{ color: "red" }}>
                                                    {t(this.state.email_error)}
                                                </label>
                                                <div className="mb-3 ">
                                                    <label>
                                                        {t('password')}
                                                        {/* <span> * </span> */}
                                                    </label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="fas fa-key"></i></span>
                                                        <input type="password" name="password" className="form-control" placeholder="**********" value={this.state.login.password} onChange={this.onInputchange} required />
                                                    </div>
                                                </div>
                                                <label className="" style={{ color: "red" }}>
                                                    {t(this.state.status)}
                                                </label>
                                            </div>

                                        </form>
                                        <div className="d-grid gap-2 mb-3">
                                            <Captcha onChange={status => {
                                                this.setState({ captchaSuccess: status })
                                                if (status) { this.setState({ captchaSuccess: status, statusCaptcha: "" }) }
                                            }
                                            } />
                                            <label className="" style={{ color: "red" }}>
                                                {t(this.state.statusCaptcha)}
                                            </label>
                                        </div>
                                        <div className="d-grid gap-2 mb-3">
                                            <button className="button button-3d button-primary nomargin" onClick={this.onSubmitForm}>{t('login')}</button>
                                        </div>
                                        <div className="d-grid gap-2 mb-3">
                                            <button className="button button-register button-3d button-primary nomargin" onClick={this.gotoreg}>{t('register')}</button>
                                        </div>
                                        <div>
                                            {t('fgyp?')}
                                            <button className="astext" data-bs-toggle="modal" data-bs-target="#forgetpass">&nbsp;{t('CH')}</button>
                                        </div>
                                        <div className="modal fade" id="forgetpass" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="staticBackdropLabel">{t('fg')}</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {t("forgetpasstext")}
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('close')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Forget Password</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                           You can contact our staff at the restaurants 
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
                </div> */}
                    <Tail />
                </>
            )
        } else {
            return (<Loaderpage />)
        }
    }
}
export default withTranslation()(Login);