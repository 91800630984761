import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Tail() {

    const { t } = useTranslation();

    return (
        <div>
            <div className="container-fluid clearfix tailpart sticky-bottom" id="copyrights">
                <div className="row">
                    <div className="col-sm">
                        <strong>
                            <ul>
                                <li>
                                    {t('mkwn')}
                                    <ul>
                                        <li>
                                            {t('mkwntel')}
                                        </li>
                                        <li>
                                            {t('opentime')}
                                        </li>
                                        <li >
                                            <a style={{ textDecoration: "none", color: "#4285F4" }} href="https://goo.gl/maps/B3xWcAk2WQEko3Zp7" target="_blank" rel="noreferrer">
                                                <i className="fas fa-map-marker-alt"></i>&nbsp;
                                                {t('mkwnaddr')}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </strong>
                    </div>
                    <div className="col-sm">
                        <div className="link">
                            <a id="facebooklink" href="https://www.facebook.com/Miyazakilaos"><i className="fab fa-facebook-square"></i>
                                &nbsp;<label>Miyazaki Teppanyaki Laos</label>
                            </a>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div style={{ fontSize: "0.7rem" }}>
                            Copyrights © 2021 All Rights Reserved by MK Restaurant.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}