import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import axios from './axios';
import NAV_LOGGED from './Navigate_Logged'
import Tail from './Tail'
import Loaderpage from './Loaderpage'
import { Avatar, Grid } from '@material-ui/core'

class Redeem extends Component {
    componentDidMount() {
        axios.get(process.env.REACT_APP_API.concat('public/Products?start=1&limit=100')).then(res => {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                data: res.data,
                total: res.data.total
            }
            ))
        }).catch(err => {
        })
        axios.get(process.env.REACT_APP_API.concat('public/members')).then(res => {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                memberdata: res.data
            }
            ))
        }).catch(err => {
        })
    }

    constructor() {
        super();
        this.state = {
            productlist: <></>,
            title: "redeemcf",
            total: 0,
            data: {
                products: [],
                total: 0
            },
            memberdata: {
                card_number: "",
                point: 0,
                member_type: {
                    image: ""
                },
            },
            redeem: {
                id: 0,
                point: 0,
                name: ''
            }, redeem_status: 0,
            redeem_info: {
                redeem_code: '',
                balance_point: 0,
                product: {
                    name: "",
                    image: ""
                },
            },
            massage: '',
            pagination: {
                current_page: 1,
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.renderSwitch = this.renderSwitch.bind(this);
        this.redeem = this.redeem.bind(this);
        this.setRedeeminfo = this.setRedeeminfo.bind(this);
        this.close_btn = this.close_btn.bind(this);
        this.formatMoney = this.formatMoney.bind(this);
    }

    formatMoney(amount) {
        return new Intl.NumberFormat('la-LA').format(amount);
    }
    handleChange(event) {
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            redeem: {
                id: event.target.value,
                point: event.target.point
            }
        }
        ))
    }
    setRedeeminfo(event) {
        this.setState(Object.assign(
            this.state, {
            ...this.state,
            redeem: { id: parseInt(event.target.id), name: event.target.name, point: event.target.value }
        }))
    }
    redeem(event) {
        this.setState(Object.assign(
            this.state, { ...this.state, redeem_status: 1 }))
        setTimeout(function () {
            axios.post(process.env.REACT_APP_API.concat('public/products'), { product_id: this.state.redeem.id }).then(res => {
                this.setState(Object.assign(
                    this.state, {
                    ...this.state, title: 'redeem_success', redeem_status: 2, redeem_info: res.data, memberdata: {
                        ...this.state.memberdata, point: this.state.memberdata.point,
                    }
                }))
            }).catch(err => {
                if (err.response) {
                    this.setState(Object.assign(
                        this.state, { ...this.state, redeem_status: 4, title: "redeem_unsuccess", message: err.response.data.message }))
                } else {
                    this.setState(Object.assign(
                        this.state, { ...this.state, redeem_status: 3, title: "redeem_unsuccess" }))
                }
            })
        }.bind(this), 2000)
    }
    close_btn() {
        this.setState(Object.assign(
            this.state, {
            ...this.state, title: "redeemcf", redeem: { id: 0, point: 0, name: '' }, redeem_status: 0, redeem_info: {
                redeem_code: '', balance_point: 0,
                product: { name: "", image: "" }
            }
        }))
        axios.get(process.env.REACT_APP_API.concat('public/Products?start=1&limit=100')).then(res => {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                data: res.data,
                total: res.data.total
            }
            ))
        }).catch(err => {
        })
    }

    renderSwitchModal(param) {
        const { t } = this.props;
        switch (param) {
            case 0:
                return (
                    <>    <button type="button" onClick={this.close_btn} className="btn btn-secondary" data-bs-dismiss="modal">{t('close')}</button>
                        <button type="button" onClick={this.redeem} className="btn btn-green ">{t('confirm')}</button></>);
            case 1:
                return (
                    <><button type="button" onClick={this.close_btn} className="btn btn-secondary" data-bs-dismiss="modal">{t('close')}</button></>);
            case 2:
                return (
                    <><button type="button" onClick={this.close_btn} className="btn btn-secondary" data-bs-dismiss="modal">{t('close')}</button></>);
            case 3:
                return (
                    <><button type="button" onClick={this.close_btn} className="btn btn-secondary" data-bs-dismiss="modal">{t('close')}</button></>);
            case 4:
                return (
                    <><button type="button" onClick={this.close_btn} className="btn btn-secondary" data-bs-dismiss="modal">{t('close')}</button></>);
            default:
                return (
                    <></>);
        }
    }
    renderSwitch(param) {
        const { t } = this.props;
        switch (param) {
            case 0:
                return (
                    <>
                        <h4 className="fw-bolder" style={{ display: "inline" }}>{t('product_name')}:&nbsp;</h4><h3 style={{ display: "inline" }}>{this.state.redeem.name}</h3>
                        <br></br>
                        <h4 className="fw-bolder" style={{ display: "inline" }}>{t('point_redeem')}:&nbsp;</h4><h3 style={{ display: "inline" }}>{this.state.redeem.point}&nbsp;{t('point')}</h3>
                        <br></br>
                        <h4 className="card-text text-danger">{t('remark2')}</h4>
                    </>
                );
            case 1:
                return <Loaderpage />;
            case 2:
                return (
                    <>
                        <div className="text-center mb-1"><i class="far fa-check-circle fa-3x text-success"></i></div>
                        <h3 className="text-center mb-1">{t('urcode')}</h3>
                        <h1 className="text-center text-success extra-bold mb-1">{this.state.redeem_info.redeem_code}</h1>
                        <h4 className="text-center mb-1">{t('showstaff')}</h4>
                        <h5 className="text-danger mb-1">{t('note')}: {t('notetext')}</h5>
                    </>
                );
            case 3:
                return (
                    <>
                        <div className="text-center mb-4"><i className="far fa-times-circle fa-3x text-danger"></i></div>
                        <h3 className="text-center mb-1">{t('sorry')}</h3>
                        <h3 className="text-center">{t('tryagain')}</h3>
                    </>
                );
            case 4:
                return (
                    <>
                        <div className="text-center mb-4"><i className="far fa-times-circle fa-3x text-danger"></i></div>
                        <h3 className="text-center mb-1">{t(this.state.message)}</h3>
                    </>
                );
            default:
                return (
                    <></>
                );
        }
    }

    render() {
        const { t } = this.props;
        var list = []
        var key;
        if (this.state.data.total) {
            for (key in this.state.data.products) {
                if (this.state.data.products[key].limit_redeem > 0) {
                    list.push(
                        <div className="col-md-12 col-lg-6 col-xl-4 mb-3" key={key}>
                            <div className="row border border-1 rounded-3 ">
                                <div className="col-4 product-img nopadding ">
                                    <Avatar style={{ height: '5.6rem', width: '5.6rem', borderRadius: '3px' }} alt="" src={this.state.data.products[key].image} variant={'square'} />
                                </div>
                                <div className="col-5 p-1">
                                    <h6 style={{ paddingLeft: '10px' }} className="mb-1">{this.state.data.products[key].name}</h6>
                                    <h6 style={{ paddingLeft: '10px' }} className="nobottommargin">{this.formatMoney(this.state.data.products[key].point)}&nbsp;{t('point')}</h6>
                                    <h6 style={{ paddingLeft: '10px' }} className="nobottommargin">{t('left')}&nbsp;{this.formatMoney(this.state.data.products[key].limit_redeem)}&nbsp;{t('unit')}</h6>
                                </div>
                                <div className="col-3 d-flex nopadding justify-content-between align-items-center">
                                    <div></div>
                                    <div>
                                        <button key={key} style={{ fontSize: "0.7rem" }} id={this.state.data.products[key].id} name={this.state.data.products[key].name} value={this.state.data.products[key].point} type="button" className="btn btn-green btn-sm" onClick={this.setRedeeminfo} data-bs-toggle="modal" data-bs-target="#redeemcf">{t('redeem')}</button>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        }
        return (
            <>
                <NAV_LOGGED />
                <div className="container-fluid clearfix bg-white">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <div className=" p-2 m-3" >
                                <h2>{t('cardinf')}</h2>
                                <div className="link entry-image">
                                    <center>
                                        <img className="img card-img-top" alt="Card" src={this.state.memberdata.member_type.image}></img>
                                    </center>
                                </div>
                                <div className="cardinfo">
                                    <h4 className="card-text">{t('cardnum')} :&nbsp;{this.state.memberdata.card_number}</h4>
                                    <h4 className="card-text">{t('availpoints')} :&nbsp;{this.formatMoney(this.state.memberdata.point - this.state.memberdata.used_point)} {t('point')}</h4>
                                    <h4 className="card-text text-danger">{t('remark1')}</h4>
                                    <button type="button" style={{ fontSize: "0.9rem" }} className="btn btn-green btn-sm " onClick={() => window.location.href = "/user"}>{t('back')}</button>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <div className=" p-2 m-3" >
                                <h2>{t('product_list')}</h2>
                                <div className="row">
                                    {list}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Tail />
                <div className="modal fade" id="redeemcf" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="redeemcf" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title" id="redeemcf">{t(this.state.title)}</h3>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.close_btn}></button>
                            </div>
                            <div className="modal-body">
                                {this.renderSwitch(this.state.redeem_status)}
                            </div>
                            <div className="modal-footer">
                                {this.renderSwitchModal(this.state.redeem_status)}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }

}
export default withTranslation()(Redeem);