import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "": "",
      "notrans": "There's no transaction right now",
      "regtitle": "Miyazaki Card Register",
      "needemail": "card number or phone number",
      "cardnum": "Miyazaki Card Number",
      "password": "Password",
      "register": "Register",
      "login": "Login",
      "bcard": "Enter the details found at the back of your Miyazaki Card.",
      "fname": "First Name ",
      "lname": "Last Name  ",
      "pnumber": "Phone Number ",
      "username": "Email ",
      "next": "Next",
      "logout": "Logout",
      "cardinf": "Member Card Info",
      "memtype": "Member type",
      "points": "Points",
      "point": "Point",
      "cardownerinfo": "Card Owner Info",
      "fullname": "Full name",
      "dob": "Date of Birth",
      "editinf": "Edit Info",
      "editinff": "Edit Password",
      "personinf": "Personal Info",
      "close": "Close",
      "update": "Update",
      "transachistory": "Transaction History",
      "dates": "Date",
      "total": "Total",
      "time": "Time",
      "mkwn": "Miyazaki Watnak Branch",
      "mkwnaddr": "In front of Lao-German Technical College, At Watnak Village, Sisattanak District, Vientiane Capital",
      "mkwntel": "Tel : 021 316541 - 021 316542",
      "opentime": "Monday - Sunday: 10:00-21:30",
      "mail": "Email",
      "carddegit": "Card Number 16 Degits",
      "oldpass": "Old Password",
      "invalid Password": "invalid Old Password",
      "invalid Email Or Password": "invalid Phone number or Card number or Password",
      "passvrf": "Please enter Password to verify that you're the owner of the account",
      "email_err": "Email format is invalid",
      "email": "Email format is invalid",
      "err_login": "Your card number or phone number or password is wrong",
      "net_problem": "Your internet is Disconnected",
      "pass_warn": "Password must more than 8 character",
      "new_password": "New Password",
      "chgpsswd": "Change Password",
      "discount": "Discount",
      "amount": "Amount",
      "lak": "LAK",
      "data_changed": "Your information is successfully changed",
      "email or phone": "Card Number or Phone Number",
      "Unique": "Card Number is used or registered",
      "uniquePhone": "This phone number has been used",
      "Card Number Not Found": "Card Number Not Found",
      "success": "Success",
      "phonedegit": "020xxxxxxxx or 030xxxxxxx",
      "cfpass": "Confirm Password",
      "ncfpass": "Confirm new password",
      "cfpasswrong": "Password doesn't match",
      "checkplease": "Please check your information again",
      "requiredMember.CardNumber": "Your Card Number is require",
      "requiredMember.Email": "Email is require",
      "requiredMember.FirstName": "Please input first name",
      "requiredMember.LastName": "Please input last name",
      "requiredMember.Password": "Password is require",
      "requiredMember.Phone": "Please input phone number",
      "phone_error": "Phone number is invalid",
      "UniqueMember.Phone": "This phone number is Used",
      "UniqueMember.CardNumber": "Member card had been registered",
      "fgyp?": "Forgot your password ?",
      "fg": "Forget Password",
      "CH": " Click Here",
      "expires": "Expires",
      "point_expired": 'Redeem Expired',
      "forgetpasstext": "If you forget your password you can ask our staff at our restaurant to reset password",
      "redeem": "Redeem",
      "Choose...": "Choose...",
      "Gender": "Gender",
      "pnumber-nd": "Second Phone Number",
      "ages": "Age Range",
      "male": "Male",
      "female": "Female",
      "other": "Other",
      "address": "Address",
      "alternate_phone_error": "Phone number is invalid",
      "not-specified": "I prefer not to say",
      "below18": "Below 18",
      "older45": "More than 45",
      "redeemt": "Redeem",
      "urcode": "Here is your redeem code",
      "confirm": "Confirm",
      "product_list": "Product List",
      "note": "note",
      "notetext": "The redeem code available on the same day you redeemed",
      "showstaff": "Please show this to our staff",
      "tryagain": "Please try again later",
      "sorry": " Something went wrong sorry for the inconvenience ",
      "product_name": "Product Name",
      "point_redeem": "Points",
      "redeem_code": "Redeem Code",
      "redeemcf": "Redeem Confirmation",
      "redeem_unsuccess": "Redeem unsuccessful",
      'redeem_success': 'Redeem success',
      "redeemhis": "Redeem History",
      "alternate_phone": "Alternate Phone",
      "back": "Back",
      "redeembtn": "Click Here to Redeem",
      "reg-text1": "Fields that include ",
      "reg-text2": " must not be empty",
      "province": "Province",
      "district": "District",
      "minMember.FirstName": "First name must more than 3 character",
      "minMember.LastName": "Last name must more than 3 character",
      "minMember.Password": "Password must more than 8 character",
      "amountpoint": "Amount of Point",
      "Vientiane capital": "Vientiane capital",
      "Chanthabuly": "Chanthabuly",
      "Sikhottabong": "Sikhottabong",
      "Xaysetha": "Xaysetha",
      "Sisattanak": "Sisattanak",
      "Naxaithong": "Naxaithong",
      "Xaythany": "Xaythany",
      "Hadxaifong": "Hadxaifong",
      "Sangthong": "Sangthong",
      "Parkngum": "Parkngum",
      "Phongsali": "Phongsali",
      "Phongsaly": "Phongsaly",
      "May": "May",
      "Khua": "Khua",
      "Samphanh": "Samphanh",
      "Bounneua": "Bounneua",
      "Nhot ou": "Nhot ou",
      "Boontai": "Boontai",
      "Louang Namtha": "Louang Namtha",
      "Luangnamtha": "Luangnamtha",
      "Sing": "Sing",
      "Long": "Long",
      "Viengphoukha": "Viengphoukha",
      "Nalae": "Nalae",
      "Oudomxai": "Oudomxai",
      "Xay": "Xay",
      "La": "La",
      "Namor": "Namor",
      "Nga": "Nga",
      "Beng": "Beng",
      "Hoon": "Hoon",
      "Pakbeng": "Pakbeng",
      "Bokeo": "Bokeo",
      "Houixay": "Houixay",
      "Tongpheung": "Tongpheung",
      "Meung": "Meung",
      "Phaoudom": "Phaoudom",
      "Paktha": "Paktha",
      "Louang Phabang": "Louang Phabang",
      "Luangprabang": "Luangprabang",
      "Xiengngeun": "Xiengngeun",
      "Nan": "Nan",
      "Parkou": "Parkou",
      "Nambak": "Nambak",
      "Ngoi": "Ngoi",
      "Pakxeng": "Pakxeng",
      "Phonxay": "Phonxay",
      "Chomphet": "Chomphet",
      "Viengkham": "Viengkham",
      "Phoukhoune": "Phoukhoune",
      "Houaphan": "Houaphan",
      "Xamneua": "Xamneua",
      "Xiengkhor": "Xiengkhor",
      "Hiam": "Hiam",
      "Viengxay": "Viengxay",
      "Huameuang": "Huameuang",
      "Xamtay": "Xamtay",
      "Sopbao": "Sopbao",
      "Add": "Add",
      "Kuan": "Kuan",
      "Xone": "Xone",
      "Xaignabouli": "Xaignabouli",
      "Xayabury": "Xayabury",
      "Khop": "Khop",
      "Hongsa": "Hongsa",
      "Ngeun": "Ngeun",
      "Xienghone": "Xienghone",
      "Phieng": "Phieng",
      "Parklai": "Parklai",
      "Kenethao": "Kenethao",
      "Botene": "Botene",
      "Thongmyxay": "Thongmyxay",
      "Xaysathan": "Xaysathan",
      "Xiangkhoang": "Xiangkhoang",
      "Pek": "Pek",
      "Kham": "Kham",
      "Nonghed": "Nonghed",
      "Khoune": "Khoune",
      "Mork": "Mork",
      "Phookood": "Phookood",
      "Phaxay": "Phaxay",
      "Vientiane": "Vientiane",
      "Phonhong": "Phonhong",
      "Thoulakhom": "Thoulakhom",
      "Keooudom": "Keooudom",
      "Kasy": "Kasy",
      "Vangvieng": "Vangvieng",
      "Feuang": "Feuang",
      "Xanakham": "Xanakham",
      "Mad": "Mad",
      "Hinherb": "Hinherb",
      "Meun": "Meun",
      "Boli khamxai": "Boli khamxai",
      "Pakxane": "Pakxane",
      "Thaphabath": "Thaphabath",
      "Pakkading": "Pakkading",
      "Bolikhanh": "Bolikhanh",
      "Khamkheuth": "Khamkheuth",
      "Viengthong": "Viengthong",
      "Xaychamphone": "Xaychamphone",
      "Khammouan": "Khammouan",
      "Thakhek": "Thakhek",
      "Mahaxay": "Mahaxay",
      "Nongbok": "Nongbok",
      "Hinboon": "Hinboon",
      "Nhommalath": "Nhommalath",
      "Bualapha": "Bualapha",
      "Nakai": "Nakai",
      "Xebangfay": "Xebangfay",
      "Xaybuathong": "Xaybuathong",
      "Khounkham": "Khounkham",
      "Savannakhet": "Savannakhet",
      "Kaisone Phomvihane": "Kaisone Phomvihane",
      "Outhoumphone": "Outhoumphone",
      "Atsaphangthong": "Atsaphangthong",
      "Phine": "Phine",
      "Xepon": "Xepon",
      "Nong": "Nong",
      "Thapangthong": "Thapangthong",
      "Songkhone": "Songkhone",
      "Champhone": "Champhone",
      "Xonbuly": "Xonbuly",
      "Xaybouly": "Xaybouly",
      "Vilabuly": "Vilabuly",
      "Atsaphone": "Atsaphone",
      "Xayphoothong": "Xayphoothong",
      "Phalanxay": "Phalanxay",
      "Salavan": "Salavan",
      "Saravane": "Saravane",
      "Ta oi": "Ta oi",
      "Toomlam": "Toomlam",
      "Lakhonepheng": "Lakhonepheng",
      "Vapy": "Vapy",
      "Kongxedone": "Kongxedone",
      "Lao ngarm": "Lao ngarm",
      "Samoi": "Samoi",
      "Xekong": "Xekong",
      "Lamarm": "Lamarm",
      "Kaleum": "Kaleum",
      "Dakcheung": "Dakcheung",
      "Thateng": "Thateng",
      "Champasak": "Champasak",
      "Pakse": "Pakse",
      "Sanasomboon": "Sanasomboon",
      "Bachiangchaleunsook": "Bachiangchaleunsook",
      "Pakxong": "Pakxong",
      "Pathoumphone": "Pathoumphone",
      "Phonthong": "Phonthong",
      "Sukhuma": "Sukhuma",
      "Moonlapamok": "Moonlapamok",
      "Khong": "Khong",
      "Attapu": "Attapu",
      "Xaysettha": "Xaysettha",
      "Samakkixay": "Samakkixay",
      "Sanamxay": "Sanamxay",
      "Sanxay": "Sanxay",
      "Phouvong": "Phouvong",
      "Sisomboun": "Sisomboun",
      "Anouvong": "Anouvong",
      "Thathom": "Thathom",
      "Longcheng": "Longcheng",
      "Hom": "Hom",
      "Longsan": "Longsan",
      "foreign": "foreign",
      "cardno": "Card number",
      "cardnoiput": "Input Card number",
      "pnumberinput": "Input Phone number",
      "availpoints": "Available points",
      "status": "Status",
      "left": "Remaining items : ",
      "unit": "left",
      "remark1": "Remarks: Your redeemed product is for reserve ONLY and not guarantee.  Subject to unavailability, until confirmed by our restaurant staff. ",
      "remark2": "Remarks: Your redeemed product is for reserve ONLY and not guarantee.  Subject to unavailability, until confirmed by our restaurant staff. ",
      "captcha wrong": "Captcha wrong"
    }
  },
  la: {
    translation: {
      "cardnoiput": "ປ້ອນເລກບັດ",
      "pnumberinput": "ປ້ອນເບີໂທ",
      "editinff": "ແກ້ໄຂລະຫັດຜ່ານ",
      "ages": "ຊ່ວງອາຍຸ",
      "regtitle": "ລົງທະບຽນ ບັດ Miyazaki",
      "cardnum": "ເລກບັດ Miyazaki",
      "password": "ລະຫັດຜ່ານ",
      "register": "ລົງທະບຽນ",
      "login": "ລົງຊື່ເຂົ້າໃຊ້",
      "bcard": "ຕື່ມຂໍ້ມູນຫຼັງບັດ Miyazaki ຂອງທ່ານ",
      "fname": "ຊື່ ",
      "cardno": "ເລກບັດ",
      "lname": "ນາມສະກຸນ ",
      "pnumber": "ເບີໂທລະສັບ ",
      "username": "ອີເມວ ",
      "next": "ຕໍ່ໄປ",
      "logout": "ອອກຈາກລະບົບ",
      "cardinf": "ຂໍ້ມູນບັດສະມາຊິກ",
      "memtype": "ປະເພດບັດ",
      "points": "ຄະແນນສະສົມ",
      "below18": "ຕ່ຳກ່ວາ 18",
      "older45": "ຫຼາຍກ່ວາ 45",
      "cardownerinfo": "ຂໍ້ມູນເຈົ້າຂອງບັດ",
      "fullname": "ຊື່ ແລະ ນາມສະກຸນ",
      "dob": "ວັນ ເດືອນ ປີ ເກີດ",
      "editinf": "ແກ້ໄຂຂໍ້ມູນ",
      "personinf": "ຂໍ້ມູນສ່ວນຕົວ",
      "close": "ປິດ",
      "update": "ອັບເດດຂໍ້ມູນ",
      "transachistory": "ປະຫວັດການນຳໃຊ້ບັດສະມາຊິກ",
      "dates": "ວັນທີ",
      "total": "ລວມເປັນເງິນ",
      "mknk": "MK ສາຂາ ນາຄຳ",
      "mknkaddr": "ຕັ້ງຢູ່ ຖະໜົນ ຫຼວງພະບາງ, ບ້ານ ນາຄຳ, ເມືອງ ສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ",
      "mknktel": "ໂທ: 021-512222, 021-512444",
      "opentime": "ວັນຈັນ - ວັນອາທິດ: 10:00-21:30",
      "mkwn": "Miyazaki ສາຂາ ວັດນາກ",
      "mkwnaddr": "ຕັ້ງຢູ່ຕໍ່ໜ້າ ໂຮງຮຽນ ເຕັກນິກ ລາວ-ເຢຍລະມັນ, ບ້ານ ວັດນາກ, ເມືອງ ສີສັດຕະນາກ, ນະຄອນຫຼວງວຽງຈັນ ",
      "mkwntel": "ໂທ 021 316541 - 021 316542",
      "mail": "ອີເມວ",
      "carddegit": "ເລກບັດ 16 ໂຕ",
      "invalid Email Or Password": "ເລກບັດ ຫຼື ເບີໂທ ຫຼື ລະຫັດບໍ່ຖືກຕ້ອງ",
      "needemail": "ເລກບັດ ຫຼື ເບີໂທ ຂອງທ່ານ",
      "oldpass": "ລະຫັດຜ່ານເກົ່າ",
      "passvrf": "ກະລຸນາໃສ່ລະຫັດ ເພື່ອຢືນຢັນວ່າທ່ານເປັນເຈົ້າຂອງບັນຊີ",
      "email_err": "ອີເມວບໍ່ຖືກຕ້ອງ",
      "email": "ອີເມວບໍ່ຖືກຕ້ອງ",
      "err_login": "ເລກບັດ ຫຼື ເບີໂທ ຫຼື ລະຫັດຂອງທ່ານບໍ່ຖືກຕ້ອງ",
      "net_problem": "ລະບົບຂັດຂ້ອງ ກະລຸນາລອງໃໝ່",
      "pass_warn": "ລະຫັດຕ້ອງຫຼາຍກ່ວາ 8ໂຕ",
      "new_password": "ລະຫັດຜ່ານໃໝ່",
      "chgpsswd": "ປ່ຽນລະຫັດ",
      "discount": "ສ່ວນຫຼຸດ",
      "amount": "ລາຄາ",
      "lak": "ກີບ",
      "data_changed": "ຂໍ້ມູນທ່ານຖືກປ່ຽນແປງແລ້ວ",
      "email or phone": "ປ້ອນເລກບັດ ຫຼື ເບີໂທລະສັບ",
      "notrans": "ບໍ່ພົບຂໍ້ມູນ",
      "Unique": "ບັດໄດ້ຖືກນໍາໃຊ້ແລ້ວ ຫຼື ຖືກລົງທະບຽນຮຽບຮ້ອຍແລ້ວ",
      "uniquePhone": "ເບີຖືກລົງທະບຽນແລ້ວ",
      "invalid Password": "ລະຫັດຜ່ານເກົ່າບໍ່ຖືກຕ້ອງ",
      "Card Number Not Found": "ບໍ່ພົບເລກບັດໃນຖານຂໍ້ມູນ",
      "success": "ສຳເລັດ",
      "phonedegit": "020xxxxxxxx ຫຼື 030xxxxxxx",
      "cfpass": "ຢືນຢັນລະຫັດຜ່ານ",
      "ncfpass": "ຢືນຢັນລະຫັດຜ່ານໃໝ່",
      "cfpasswrong": "ຢືນຢັນລະຫັດຜ່ານບໍ່ກົງກັນ",
      "checkplease": "ກະລຸນາກວດສອບຂໍ້ມູນອີກຄັ້ງ",
      "requiredMember.CardNumber": "ເລກບັດຂອງທ່ານບໍ່ຖືກຕ້ອງ",
      "requiredMember.Email": "ອີເມວບໍ່ຖືກຕ້ອງ",
      "requiredMember.FirstName": "ຊື່ຂອງທ່ານບໍ່ຖືກຕ້ອງ",
      "requiredMember.LastName": "ນາມສະກຸນ ຂອງທ່ານບໍ່ຖືກຕ້ອງ",
      "requiredMember.Password": "ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ",
      "requiredMember.Phone": "ເບີໂທບໍ່ຖືກຕ້ອງ",
      "phone_error": "ເບີໂທບໍ່ຖືກຕ້ອງ",
      "UniqueMember.Phone": "ເບີຖືກລົງທະບຽນແລ້ວ",
      "UniqueMember.CardNumber": "ບັດຖືກລົງທະບຽນແລ້ວ",
      "fgyp?": "ທ່ານລືມລະຫັດແມ່ນບໍ່?",
      "CH": " ກົດບ່ອນນີ້",
      "fg": "ລືມລະຫັດ",
      "expires": "ວັນໝົດອາຍຸບັດ",
      "point_expired": 'ວັນໝົດອາຍຸຄະແນນສະສົມ',
      "forgetpasstext": "ຖ້າທ່ານລືມລະຫັດ ທ່ານສາມາດຂໍລີເຊັດລະຫັດນຳພະນັກງານທີ່ຮ້ານຂອງເຮົາຕາມແຕ່ລະສາຂາໄດ້",
      "redeem": "ແລກ",
      "Choose...": "ເລືອກ...",
      "Gender": "ເພດ",
      "pnumber-nd": "ເບິໂທລະສັບສຳຮອງ",
      "age": "ອາຍຸ",
      "male": "ຊາຍ",
      "female": "ຍິງ",
      "other": "ອື່ນໆ",
      "address": "ທີ່ຢູ່",
      "alternate_phone": "ເບີໂທສຳຮອງ",
      "alternate_phone_error": "ເບີໂທບໍ່ຖືກຕ້ອງ",
      "not-specified": "ບໍ່ລະບຸ",
      "Vientiane capital": "ນະຄອນຫລວງວຽງຈັນ",
      "Chanthabuly": "ຈັນທະບູລີ",
      "Sikhottabong": "ສີໂຄດຕະບອງ",
      "Xaysetha": "ໄຊເສດຖາ",
      "Sisattanak": "ສີສັດຕະນາກ",
      "Naxaithong": "ນາຊາຍທອງ",
      "Xaythany": "ໄຊທານີ",
      "Hadxaifong": "ຫາດຊາຍຟອງ",
      "Sangthong": "ສັງທອງ",
      "Parkngum": "ປາກງື່ມ",
      "Phongsali": "ຜົ້ງສາລີ",
      "Phongsaly": "ຜົ້ງສາລີ",
      "May": "ໃຫມ່",
      "Khua": "ຂວາ",
      "Samphanh": "ສຳພັນ",
      "Bounneua": "ບູນເຫນືອ",
      "Nhot ou": "ຍອດອູ",
      "Boontai": "ບູນໃຕ້",
      "Louang Namtha": "ຫລວງນ້ຳທາ",
      "Luangnamtha": "ຫລວງນ້ຳທາ",
      "Sing": "ສິງ",
      "Long": "ລອງ",
      "Viengphoukha": "ວຽງພູຄາ",
      "Nalae": "ນາແລ",
      "Oudomxai": "ອຸດົມໄຊ",
      "Xay": "ໄຊ",
      "La": "ຫລາ",
      "Namor": "ນາໝໍ້ ",
      "Nga": "ງາ",
      "Beng": "ແບງ",
      "Hoon": "ຮຸນ",
      "Pakbeng": "ປາກແບງ",
      "Bokeo": "ບໍ່ແກ້ວ",
      "Houixay": "ຫ້ວຍຊາຍ",
      "Tongpheung": "ຕົ້ນເຜິ້ງ",
      "Meung": "ເມິງ",
      "Phaoudom": "ຜາອຸດົມ",
      "Paktha": "ປາກທາ",
      "Louang Phabang": "ຫຼວງພະບາງ",
      "Luangprabang": "ຫຼວງພະບາງ",
      "Xiengngeun": "ຊຽງເງິນ",
      "Nan": "ນານ",
      "Parkou": "ປາກອູ",
      "Nambak": "ນ້ຳບາກ",
      "Ngoi": "ງອຍ",
      "Pakxeng": "ປາກແຊງ",
      "Phonxay": "ໂພນໄຊ",
      "Chomphet": "ຈອມເພັດ",
      "Phoukhoune": "ພູຄູນ",
      "Houaphan": "ຫົວພັນ",
      "Xamneua": "ຊຳເໜືອ",
      "Xiengkhor": "ຊຽງຄໍ້",
      "Hiam": "ຮ້ຽມ",
      "Viengxay": "ວຽງໄຊ",
      "Huameuang": "ຫົວເມືອງ",
      "Xamtay": "ຊຳໃຕ້",
      "Sopbao": "ສົບເບົາ",
      "Add": "ແອດ",
      "Kuan": "ກວັນ",
      "Xone": "ຊອນ",
      "Xaignabouli": "ໄຊຍະບູລີ",
      "Xayabury": "ໄຊຍະບູລີ",
      "Khop": "ຄອບ",
      "Hongsa": "ຫົງສາ",
      "Ngeun": "ເງິນ",
      "Xienghone": "ຊຽງຮ່ອນ",
      "Phieng": "ພຽງ",
      "Parklai": "ປາກລາຍ",
      "Kenethao": "ແກ່ນທ້າວ",
      "Botene": "ບໍ່ແຕນ",
      "Thongmyxay": "ທົ່ງມີໄຊ",
      "Xaysathan": "ໄຊຊະຖານ",
      "Xiangkhoang": "ຊຽງຂວາງ",
      "Pek": "ແປກ",
      "Kham": "ຄຳ",
      "Nonghed": "ໜອງແຮດ",
      "Khoune": "ຄູນ",
      "Mork": "ໝອກ",
      "Phookood": "ພູກູດ",
      "Phaxay": "ຜາໄຊ",
      "Vientiane": "ວຽງຈັນ",
      "Phonhong": "ໂພນໂຮງ",
      "Thoulakhom": "ທຸລະຄົມ",
      "Keooudom": "ແກ້ວອຸດົມ",
      "Kasy": "ກາສີ",
      "Vangvieng": "ວັງວຽງ",
      "Feuang": "ເຟືອງ",
      "Xanakham": "ຊະນະຄາມ",
      "Mad": "ແມດ",
      "Viengkham": "ວຽງຄຳ",
      "Hinherb": "ຫີນເຫີບ",
      "Meun": "ໝື່ນ",
      "Boli khamxai": "ບໍລິຄຳໄຊ",
      "Pakxane": "ປາກຊັນ",
      "Thaphabath": "ທ່າພະບາດ",
      "Pakkading": "ປາກກະດິງ",
      "Bolikhanh": "ບໍລິຄັນ",
      "Khamkheuth": "ຄຳເກີດ",
      "Viengthong": "ວຽງທອງ",
      "Xaychamphone": "ໄຊຈຳພອນ",
      "Khammouan": "ຄຳມ່ວນ",
      "Thakhek": "ທ່າແຂກ",
      "Mahaxay": "ມະຫາໄຊ",
      "Nongbok": "ໜອງບົກ",
      "Hinboon": "ຫີນບູນ",
      "Nhommalath": "ຍົມມະລາດ",
      "Bualapha": "ບົວລະພາ",
      "Nakai": "ນາກາຍ",
      "Xebangfay": "ເຊບັ້ງໄຟ",
      "Xaybuathong": "ໄຊບົວທອງ",
      "Khounkham": "ຄູນຄຳ",
      "Savannakhet": "ສະຫວັນນະເຂດ",
      "Kaisone Phomvihane": "ໄກສອນ ພົມວິຫານ",
      "Outhoumphone": "ອຸທຸມພອນ",
      "Atsaphangthong": "ອາດສະພັງທອງ",
      "Phine": "ພີນ",
      "Xepon": "ເຊໂປນ",
      "Nong": "ນອງ",
      "Thapangthong": "ທ່າປາງທອງ",
      "Songkhone": "ສອງຄອນ",
      "Champhone": "ຈຳພອນ",
      "Xonbuly": "ຊົນບູລີ",
      "Xaybouly": "ໄຊບູລີ",
      "Vilabuly": "ວິລະບູລີ",
      "Atsaphone": "ອາດສະພອນ",
      "Xayphoothong": "ໄຊພູທອງ",
      "Phalanxay": "ພະລານໄຊ",
      "Salavan": "ສາລະວັນ",
      "Saravane": "ສາລະວັນ",
      "Ta oi": "ຕາໂອ້ຍ",
      "Toomlam": "ຕຸ້ມລານ",
      "Lakhonepheng": "ລະຄອນເພັງ",
      "Vapy": "ວາປີ",
      "Kongxedone": "ຄົງເຊໂດນ",
      "Lao ngarm": "ເລົ່າງາມ",
      "Samoi": "ສະມ້ວຍ",
      "Xekong": "ເຊກອງ",
      "Lamarm": "ລະມາມ",
      "Kaleum": "ກະລືມ",
      "Dakcheung": "ດາກຈຶງ",
      "Thateng": "ທ່າແຕງ",
      "Champasak": "ຈຳປາສັກ",
      "Pakse": "ປາກເຊ",
      "Sanasomboon": "ຊະນະສົມບູນ",
      "Bachiangchaleunsook": "ບາຈຽງຈະເລີນສຸກ",
      "Pakxong": "ປາກຊ່ອງ",
      "Pathoumphone": "ປະທຸມພອນ",
      "Phonthong": "ໂພນທອງ",
      "Sukhuma": "ສຸຂຸມາ",
      "Moonlapamok": "ມຸນລະປະໂມກ",
      "Khong": "ໂຂງ",
      "Attapu": "ອັດຕະປື",
      "Xaysettha": "ໄຊເສດຖາ",
      "Samakkixay": "ສາມະຄີໄຊ",
      "Sanamxay": "ສະໜາມໄຊ",
      "Sanxay": "ສານໄຊ",
      "Phouvong": "ພູວົງ",
      "Sisomboun": "ໄຊສົມບູນ",
      "Anouvong": "ອານຸວົງ",
      "Thathom": "ທ່າໂທມ",
      "Longcheng": "ລ້ອງແຈ້ງ",
      "Hom": "ຮົ່ມ",
      "Longsan": "ລ້ອງຊານ",
      "foreign": "ຕ່າງປະເທດ",
      "redeemcf": "ຢືນຢັນການແລກຄະແນນ",
      "redeemhis": "ປະຫວັດການແລກຄະແນນ",
      "product_name": "ລາຍການຂໍແລກ",
      "redeem_code": "ລະຫັດແລກ",
      "point": "ຄະແນນ",
      "amountpoint": "ຄະແນນທີ່ໄດ້ຮັບ",
      'redeem_success': 'ການແລກສຳເລັດ',
      "urcode": "ລະຫັດການແລກຂອງທ່ານ",
      'redeem_unsuccess': 'ການແລກບໍ່ສຳເລັດ',
      "confirm": "ຢືນຢັນ",
      "product_list": "ລາຍການສິນຄ້າ",
      "point_redeem": "ຄະແນນຂໍແລກ",
      "note": "ໝາຍເຫດ",
      "notetext": "ການແລກຄະແນນຂອງທ່ານແມ່ນສາມາກໃຊ້ໄດ້ມື້ນີ້ເທົ່ານັ້ນ",
      "showstaff": "ກະລຸນາສະແດງລະຫັດນີ້ຕໍ່ພະນັກງານ",
      "tryagain": "ກະລຸນາລອງໃໝ່ອີກຄັ້ງ",
      "sorry": "ຂໍອະໄພ ລະບົບຂັດຂ້ອງ",
      "Your Point Not Enough": "ຄະແນນສະສົມຂອງທ່ານບໍ່ພຽງພໍໃນການແລກ",
      "back": "ກັບຄືນ",
      "time": "ເວລາ",
      "redeembtn": "ກົດບ່ອນນີ້ເພື່ອແລກຄະແນນ",
      "reg-text1": "ໝາຍເຫດ: ຊ່ອງທີ່ມີເຄື່ອງໝາຍ ",
      "reg-text2": " ຈຳເປັນຕ້ອງໄດ້ປ້ອນຂໍ້ມູນ",
      "province": "ແຂວງ",
      "district": "ເມືອງ",
      "minMember.FirstName": "ປ້ອນຊື່ຕ້ອງຫຼາຍກວ່າ 3 ຕົວອັກສອນຂື້ນໄປ",
      "minMember.LastName": "ປ້ອນນາມສະກຸນຕ້ອງຫຼາຍກວ່າ 3 ຕົວອັກສອນຂື້ນໄປ",
      "availpoints": "ຄະແນນທີ່ໃຊ້ໄດ້",
      "status": "ສະຖານະ",
      "left": "ຍັງເຫຼືອ",
      "unit": "ອັນ",
      "remark1": "ໝາຍເຫດ: ສິນຄ້າທີ່ທ່ານກົດແລກ ແມ່ນເປັນພຽງການຈອງ ແລະ ອາດຈະບໍ່ໄດ້ຕາມຈໍານວນທີ່ທ່ານແລກໄວ້ ຈົນກ່ວາຈະໄດ້ຮັບການຢືນຢັນຈາກທາງຮ້ານ.",
      "remark2": "ໝາຍເຫດ: ສິນຄ້າທີ່ທ່ານກົດແລກແມ່ນມີຈໍານວນຈໍາກັດ, ທາງຮ້ານຂໍສະຫງວນສິດໃຫ້ກັບສະມາຊິກທີ່ເຂົ້າມາຢືນຢັນກັບທາງຮ້ານກ່ອນ",
      "captcha wrong": "ເລກ Captcha ບໍ່ຖືກຕ້ອງ"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("mk_lang") || "la",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;